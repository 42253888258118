#root, .App {
  text-align: center;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#camerafeed{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.brackets {
  width: 100vw;
  text-align: center;
}
.brackets img {
  width: 80vw;
}

.link {
  position: fixed;
  left: 5px;
  top: 5px;

  svg {
    enable-background:new 0 0 350 350;
    width: 50px;
  }

  .st0 {
    fill:none;stroke:#FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
